import { useTranslation } from "react-i18next";

import "./GraphLabel.scss";

interface GraphLabelProps {
  iconSrc: string;
  value: number;
  valueType: "count" | "sum";
  valuePercentage: number;
  labelDescription?: string;
}

const positiveValueColor = "#299450";
const negativeValueColor = "#FD3264";

export default function GraphLabel({ iconSrc, value, valueType, valuePercentage, labelDescription }: GraphLabelProps) {
  const { t } = useTranslation();

  let color: string | undefined;
  if (value !== 0) {
    color = value > 0 ? positiveValueColor : negativeValueColor;
  }

  let percentageLabel = valuePercentage === 0 ? "" : `(${value > 0 ? "+" : "-"} ${Math.abs(valuePercentage).toLocaleString("da-DK", { maximumFractionDigits: 0 })}%)`;
  let label = `${value > 0 ? "+" : "-"} ${Math.abs(value).toLocaleString("da-DK", { maximumFractionDigits: 0 })} ${valueType === "count" ? t("common:OrderCount") : t("common:Currency")}`;

  return (
    <div className="graph-label">
      <img className="graph-icon" alt="" src={iconSrc} />
      <p className="graph-label-text">
        <span className="label-highlight" style={{ color }}>
          <strong>{label}</strong> {percentageLabel}
        </span>{" "}
        {labelDescription && (
          <span>
            {t("graphs:ComparedTo").toLowerCase()} <strong>{labelDescription.toLowerCase()}</strong>
          </span>
        )}
      </p>
    </div>
  );
}
